import { PageDocumentSlugOnly, PageTypeToPathFunction } from './types/types';
import { urlJoin } from './utils/utils';

// TODO review all these constants
export const AUTHOR_NAME = 'Dinner in the Sky';
export const COPYRIGHT_DEFAULT_YEAR = 2024;

export const DEFAULT_LANG = 'en';
export const ALL_LANGS = [DEFAULT_LANG, 'pt'] as const;

// WARNING When updating these three constants update in cms constants too
// WARNING When updating these three constants update in site create_redirects_file_from_cms too
export const ARTICLES_PREFIX_URL = 'blog';
export const EXPERIENCES_PREFIX_URL = 'experiences';

export const PAGE_DOCUMENT_TYPES = ['page', 'experience'] as const;

// WARNING When updating these two constants update in site variables and cms constants too
export const DARK_THEME_COLOR = '#7532C9';
export const LIGHT_THEME_COLOR = '#FFFFFF';
export const ACCENT_THEME_COLOR = '#00a3ff';

export const PAGE_TYPE_TO_PATH_FUNCTION = {
  page: (pageDoc: PageDocumentSlugOnly) => urlJoin(pageDoc.slug.current),
  experience: (experienceDoc: PageDocumentSlugOnly) =>
    urlJoin(EXPERIENCES_PREFIX_URL, experienceDoc.slug.current),
} as const satisfies PageTypeToPathFunction;

export const SCRIPT_BOOKINGKIT_ID = '4959f875873f782806b970e688fe9e2f';
export const CW_BOOKINGKIT_ID = '0d491388c40777be530aa6e75db08134';

/**
 *
 * Below here are constants we normally don't need to change or check
 *
 */

export const USE_COOKIES_BANNER = process.env.GATSBY_USE_COOKIES_BANNER;

export const SITE_DOMAIN =
  process.env.NODE_ENV === 'development'
    ? 'localhost:8000'
    : process.env.GATSBY_SITE_DOMAIN || 'null.com';

export const SITE_BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8000'
    : process.env.GATSBY_SITE_BASE_URL || 'https://www.null.com';

export const MAX_OG_DESCRIPTION_LENGTH = 300;

export const GOOGLE_OPTIMIZE_ID = process.env.GATSBY_GOOGLE_OPTIMIZE_ID;
export const SHOULD_RUN_GOOGLE_OPTIMIZE =
  process.env.NODE_ENV === `production` && !!GOOGLE_OPTIMIZE_ID;
