import { graphql, useStaticQuery } from 'gatsby';
import { PAGE_TYPE_TO_PATH_FUNCTION } from '../constants';
import {
  PageDocument,
  PageDocumentType,
  PortableTextBlock,
  RawPortableText,
  VersatileLink,
} from '../types/types';
import { capitalize, flatten, uniqBy } from './nodash';

/**
 * Returns a url path with the pageReference's slug and the right prefix
 * depending on the pageReferences's __typename
 */
export function getPageDocumentUrl(pageDocument: PageDocument, anchor?: string): string {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const typeName = (pageDocument._type || pageDocument.__typename)!;

  let cleanTypename: string = typeName;
  if (cleanTypename.startsWith('Sanity')) {
    cleanTypename = cleanTypename.replace('Sanity', '');
    cleanTypename = cleanTypename.charAt(0).toLowerCase() + cleanTypename.slice(1);
  }
  if (!(cleanTypename in PAGE_TYPE_TO_PATH_FUNCTION)) {
    throw new Error('typeName value not known: ' + cleanTypename);
  }
  return (
    PAGE_TYPE_TO_PATH_FUNCTION[cleanTypename as PageDocumentType](pageDocument) +
    (anchor ? '#' + anchor.replace('#', '') : '')
  );
}

/**
 * Returns a url depending on whether the versatileLink has an internalLink, externalLink or a file.
 * If it has a internalLink:
 * - with a pageReference, returns a url path with the pageReference's slug
 *   and the right prefix depending on the pageReferences's __typename.
 * - without a pageReference, returns the anchor starting with a #
 * If it has a externalLink, returns the url.
 * If it has a file, returns the asset's url,
 * with our without ?dl according to the download option.
 */
export function getUrlFromVersatileLink(versatileLink: VersatileLink): string {
  const { internalLink, externalLink, file } = versatileLink;
  if (internalLink) {
    if (internalLink.pageReference) {
      return getPageDocumentUrl(internalLink.pageReference, internalLink.anchorLink);
    } else {
      return '#' + internalLink.anchorLink.replace('#', '');
    }
  }

  if (file) {
    return file.asset.url + (file.download ? '?dl' : '');
  }

  return externalLink.url;
}

function langFieldToRaw(langField: string) {
  return '_raw' + langField[0].toUpperCase() + langField.substr(1);
}

/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-argument */
export function localize(value: any, languages: Array<string>): any {
  if (Array.isArray(value)) {
    return value.map(v => localize(v, languages));
  } else if (value !== null && typeof value === 'object') {
    if (/^SanityLocale/.test(value.__typename) || /^locale_/.test(value._type)) {
      const field = languages.find(lang => value[lang]);
      if (field && value[field]) {
        return localize(value[field], languages);
      }
      const foundField = languages.find(lang => value[langFieldToRaw(lang)]);
      const rawField = foundField && langFieldToRaw(foundField);
      if (rawField && value[rawField]) {
        return localize(value[rawField], languages);
      }
      return undefined;
    }
    return Object.keys(value).reduce((result: any, key: string) => {
      result[key] = localize(value[key], languages);
      return result;
    }, {});
  }
  return value;
}
/* eslint-enable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return,  @typescript-eslint/no-unsafe-argument */

function genBlockKey() {
  return [...Array<never>(12)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
}

function blockConfigToBlock(blockConfig) {
  if (blockConfig.type === 'list') {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
    return blockConfig.entries.map(entry => ({
      ...blockConfigToBlock(entry),
      listItem: blockConfig.listType,
      level: 2,
    }));
  }
  const blockConfigObj =
    typeof blockConfig === 'string' ? { text: blockConfig, style: 'normal' } : blockConfig;
  return {
    _key: genBlockKey(),
    _type: 'block',
    children: [
      {
        _key: genBlockKey(),
        _type: 'span',
        marks: [],
        text: blockConfigObj.text,
      },
    ],
    markDefs: [],
    style: blockConfigObj.style,
  };
}

export function getPortableText(blockConfigs): RawPortableText {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call
  return flatten(blockConfigs.map(blockConfigToBlock));
}

export function getPortableTextAsString(rawPortableText: RawPortableText): string {
  return rawPortableText
    .filter(block => block._type === 'block')
    .map(block => (block as PortableTextBlock).children.map(child => child.text).join(''))
    .join('\n');
}

interface SanityConfigQueryData {
  site: {
    siteMetadata: {
      projectId: string;
      dataset: string;
    };
  };
}

export function useSanityConfigData() {
  return useStaticQuery<SanityConfigQueryData>(graphql`
    {
      site {
        siteMetadata {
          projectId: sanityProjectId
          dataset: sanityDataset
        }
      }
    }
  `).site.siteMetadata;
}

export function getIdWithoutDraft(sanityId: string): string {
  return sanityId.replace(/^drafts\./, '');
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function addRawToPortableTextKeys(data: any): any {
  if (Array.isArray(data)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return data.map(el => addRawToPortableTextKeys(el));
  } else if (data !== null && typeof data === 'object') {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
    return Object.keys(data).reduce((result: any, key: string) => {
      const value = data[key];
      // Test if value is a portable text, i.e. an object with a _type = 'block'
      if (
        Array.isArray(value) &&
        value.some(
          el => el !== null && typeof el === 'object' && '_type' in el && el._type === 'block',
        )
      ) {
        result['_raw' + capitalize(key)] = value;
      } else {
        result[key] = addRawToPortableTextKeys(value);
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return result;
    }, {});
  }
  return data;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function addTypenameKeys(data: any): any {
  if (Array.isArray(data)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return data.map(el => addTypenameKeys(el));
  } else if (data !== null && typeof data === 'object') {
    const dataWithTypenameKeys = Object.fromEntries(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      Object.entries(data).map(([key, value]) => [key, addTypenameKeys(value)]),
    );
    if ('_type' in dataWithTypenameKeys) {
      return {
        ...dataWithTypenameKeys,

        __typename:
          'Sanity' +
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
          capitalize(data._type.replace(/-([a-z])/g, (_, letter) => letter.toUpperCase())),
      };
    } else {
      return dataWithTypenameKeys;
    }
  }
  return data;
}

export function findPathsInObjectByPredicate(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  predicate: (value: any) => boolean,
  currentPath: Array<string> = [],
): Array<Array<string>> {
  let paths: Array<Array<string>> = [];

  for (const key in object) {
    if (predicate(object[key])) {
      paths.push([...currentPath, key]);
    } else {
      if (Array.isArray(object[key])) {
        const elsPaths: Array<Array<string>> = [];
        for (let i = 0; i < object[key].length; i++) {
          const element = object[key][i];
          if (predicate(element)) {
            paths.push([...currentPath, key + '[]']);
          }
          elsPaths.push(
            ...findPathsInObjectByPredicate(element, predicate).map(path =>
              '_type' in element ? [`_type == '${element._type}'`, ...path] : path,
            ),
          );
        }
        const newPaths = uniqBy(elsPaths, elsPath => JSON.stringify(elsPath)).map(elsPath => [
          ...currentPath,
          key + '[]',
          ...elsPath,
        ]);
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (newPaths && newPaths.length > 0) {
          paths = [...paths, ...newPaths];
        }
      } else if (typeof object[key] === 'object' && object[key] !== null) {
        paths = [
          ...paths,
          ...findPathsInObjectByPredicate(object[key], predicate, [...currentPath, key]),
        ];
      }
    }
  }

  return paths;
}

export function checkPathExistsInData(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  path: Array<string>,
): boolean {
  if (path.length === 0) {
    return true;
  }
  const pathEl = path[0];
  const restPath = path.slice(1);
  if (pathEl.startsWith('_type == ')) {
    return checkPathExistsInData(data, restPath);
  }
  const key = pathEl.replace(/\[\]$/, '');
  const isArray = pathEl.endsWith('[]');
  if (data !== null && typeof data === 'object') {
    if (!(key in data) || Array.isArray(data[key]) !== isArray) {
      return false;
    }
    if (Array.isArray(data[key])) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
      return data[key].some(el => checkPathExistsInData(el, restPath));
    }
    return checkPathExistsInData(data[key], restPath);
  } else {
    return false;
  }
}

type GroqQueryEllipsisNode = {
  type: 'ellipsis';
};

type GroqQueryNodeChildrenFragment = {
  children: Array<GroqQueryNode>;
};

type GroqQueryConditionalNode = {
  type: 'conditional';
  key: string;
};

type GroqQueryConditionalNodeWithChildren = GroqQueryConditionalNode &
  GroqQueryNodeChildrenFragment;

type GroqQueryNormalNode = {
  key: string;
  keyType?: 'alias';
  type: 'object' | 'array';
  query?: string;
  reference?: true;
};

type GroqQueryNormalNodeWithChildren = GroqQueryNormalNode & GroqQueryNodeChildrenFragment;

type GroqQueryNodeWithChildren =
  | GroqQueryNormalNodeWithChildren
  | GroqQueryConditionalNodeWithChildren;

type GroqQueryNode =
  | GroqQueryNodeWithChildren
  | GroqQueryNormalNode
  | GroqQueryConditionalNode
  | GroqQueryEllipsisNode;

export function parseGroqQuery(query: string): GroqQueryNodeWithChildren {
  const tokens = flatten(
    query
      .replaceAll('\n', ' ')
      .replaceAll(/\s+/g, ' ')
      .split(/(?:([_\-a-zA-Z0-9]+ == [_\-a-zA-Z0-9']+ =>)|("[_\-a-zA-Z0-9]+": \*.*?)(?= {)| )/)
      .filter(Boolean)
      .map(s =>
        // Splits entries with format: "something": *[...]
        s.match(/^"[_\-a-zA-Z0-9]+": \*.*?$/)
          ? [s.slice(0, s.indexOf(':') + 1), s.slice(s.indexOf(':') + 2)]
          : s,
      ),
  );

  const groqTree: GroqQueryNodeWithChildren = {
    key: '<root>',
    type: 'object',
    children: [] as Array<GroqQueryNode>,
  };

  const groqChildrenStack: Array<GroqQueryNodeWithChildren> = [groqTree];
  let expectObjectOpenBraces: 'yes' | 'no' | 'maybe' = 'yes';

  for (const token of tokens) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const currentNode = groqChildrenStack.at(-1)!;

    if (expectObjectOpenBraces === 'maybe' && token !== '{') {
      expectObjectOpenBraces = 'no';
    }

    if (token.startsWith('*[')) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const nextNode = currentNode.children.at(-1)! as GroqQueryNormalNode;
      nextNode.query = token;
      expectObjectOpenBraces = 'yes';
    } else if (token === '{') {
      if (expectObjectOpenBraces === 'no') {
        throw new Error('Got { token but was not expecting it');
      }
      if (currentNode.children.length === 0 && currentNode['key'] !== '<root>') {
        throw new Error('Current node has no children and is not root');
      }
      if (currentNode.children.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const nextNode = currentNode.children.at(-1)! as GroqQueryNodeWithChildren;
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion, @typescript-eslint/no-non-null-assertion
        nextNode!.children = [];
        groqChildrenStack.push(nextNode);
      }
      expectObjectOpenBraces = 'no';
    } else if (token.match(/^},?$/)) {
      groqChildrenStack.pop();
    } else if (token.match(/^\.\.\.,?$/)) {
      const newChild: GroqQueryEllipsisNode = {
        type: 'ellipsis',
      };
      currentNode.children.push(newChild);
    } else if (token.match(/ =>$/)) {
      const newChild: GroqQueryConditionalNode = {
        type: 'conditional',
        key: token.replace(/ =>$/, ''),
      };
      currentNode.children.push(newChild);
      expectObjectOpenBraces = 'yes';
    } else if (token.match(/^"\w+":$/)) {
      const newChild: GroqQueryNormalNode = {
        key: token.slice(1, token.length - 2),
        keyType: 'alias',
        type: 'object',
      };
      currentNode.children.push(newChild);
      expectObjectOpenBraces = 'maybe';
    } else if (token.match(/^\w+(\[\])?(->)?,?$/)) {
      let cleanedToken = token;
      cleanedToken = cleanedToken.endsWith(',') ? cleanedToken.replace(/,$/, '') : cleanedToken;
      const reference = cleanedToken.endsWith('->');
      cleanedToken = reference ? cleanedToken.replace(/->$/, '') : cleanedToken;
      const isArray = cleanedToken.endsWith('[]');
      cleanedToken = isArray ? cleanedToken.replace(/\[\]$/, '') : cleanedToken;
      const type = isArray ? 'array' : 'object';
      const key = cleanedToken;
      const newChild: GroqQueryNormalNode = {
        key,
        type,
        reference: reference ? true : undefined,
      };
      currentNode.children.push(newChild);
      expectObjectOpenBraces = 'maybe';
    } else {
      throw new Error('Got unexpected token: ' + token);
    }
  }

  return groqTree;
}

export function getGroqQueryFromTree(node: GroqQueryNode, indentationSize = 0): string {
  const indentation = Array(indentationSize).fill(' ').join('');
  let preBrace: string | null = null;
  if (node.type === 'ellipsis') {
    preBrace = '...';
  } else if (node.type === 'conditional') {
    preBrace = node.key + ' =>';
  } else {
    preBrace = node.key !== '<root>' ? node.key : '';
    if (node.keyType === 'alias') {
      preBrace = `"${preBrace}":`;
    }
    preBrace =
      preBrace +
      (node.type === 'array' ? '[]' : '') +
      (node.reference ? '->' : '') +
      (node.query ? ' ' + node.query : '');
  }
  if ('children' in node) {
    return (
      indentation +
      `${preBrace ? preBrace + ' ' : ''}{\n${node.children
        .map(childNode => getGroqQueryFromTree(childNode, indentationSize + 2))
        .join(`,\n`)}\n${indentation}}`
    );
  } else {
    return indentation + preBrace;
  }
}

export function addReferencePathToQueryNode(
  node: GroqQueryNodeWithChildren,
  referencePath: Array<string>,
): GroqQueryNodeWithChildren {
  let currentChildren = node.children;

  for (const pathEl of referencePath) {
    const pathElKey = pathEl.split('[]')[0];
    const pathElType = pathEl.startsWith('_type ==')
      ? 'conditional'
      : pathEl.endsWith('[]')
      ? 'array'
      : 'object';
    let child = currentChildren.find(child => 'key' in child && child.key === pathElKey) as
      | GroqQueryNodeWithChildren
      | undefined;

    if (!child) {
      if (pathElType === 'conditional') {
        child = {
          key: pathElKey,
          type: 'conditional',
          children: [],
        };
        currentChildren.push(child);
        currentChildren = child.children;
      } else {
        if (pathEl === referencePath.at(-1)) {
          // Last path element, just push the missing reference object
          currentChildren.push({
            key: pathElKey,
            type: pathElType,
            reference: true,
          });
        } else {
          // We're in the middle of the path
          child = {
            key: pathElKey,
            type: pathElType,
            children: [
              {
                type: 'ellipsis',
              },
            ],
          };
          currentChildren.push(child);
          currentChildren = child.children;
        }
      }
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      child.children = child.children || [
        {
          type: 'ellipsis',
        },
      ];
      currentChildren = child.children;
    }
  }
  return node;
}

export function removeDraftsFromId(sanityId: string): string {
  return sanityId.replace(/^drafts./, '');
}
